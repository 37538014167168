<template>
  <div>
    <puls-navigation-list
      :items="surveysToDisplay"
      :didSelectItem="navigateToSurvey"
    >
      <template #details="slotProps">
        <i
          v-if="slotProps.item.icon"
          :class="`bi bi-${slotProps.item.icon} fs-3 text-${slotProps.item.variant} me-5`"
        ></i>
      </template>
    </puls-navigation-list>
  </div>
</template>

<script>
import { PulsNavigationList } from '@puls-solutions/puls-ui-components'
export default {
  name: 'ResultsSurveysList',
  components: {
    PulsNavigationList
  },
  props: {
    surveys: Array
  },

  computed: {
    surveysToDisplay() {
      return this.surveys?.length
        ? this.surveys.map((survey) => ({
            ...survey,
            title: this.$tr(survey.details.messages, 'title'),
            text: this.$t('results.participants', {
              participants: survey.details.userCount
            }),
            to: `/results/#/l/${survey.name}`,
            icon: this.detailsForSurvey(survey)?.icon,
            variant: this.detailsForSurvey(survey)?.variant
          }))
        : []
    }
  },
  methods: {
    detailsForSurvey(survey) {
      if (survey?.details?.status === 'published') {
        return { icon: 'arrow-repeat', variant: 'info' }
      }
      if (survey?.details?.status === 'completed') {
        return { icon: 'bi-check-lg', variant: 'success' }
      }
    },
    navigateToSurvey(survey) {
      window.location = `/results/#/l/${survey.name}`
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.main-panel > .content {
  margin-top: 0;
}

.remaining {
  color: #888;
}

.survey-item {
  border: none;

  .name,
  .complete {
    width: 100%;
    height: 24px;

    .progress {
      width: 100%;
    }
  }
}
</style>
